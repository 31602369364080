/*
Copyright 2019 Province of British Columbia

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at 

   http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.

Created by Patrick Simonian
*/
import { cloneDeep } from 'lodash';

// the raw graph ql query return an array of objects that contains a node property which
// can be flatten to just return an array of nodes
export const flattenGatsbyGraphQL = edges => edges.map(node => node.node);

/**
 * converts an array to a map like object and stores the list of props as an array
 * which is useful for lookups
 * ArrayToMap([{id: 'apple', name: 'baz'}, {id: 'banana', name: 'foo'}], 'id')
 * => {
 *  map: {
 *   apple: { ... },
 *   banana: { ...},
 *  },
 *  all: ['apple', 'banana'],
 * }
 * @param {Array} array an array of objects
 * @param {String} by a string identifying a property in a single item within the array
 * @returns {Object} the converted map and a lookup array
 */
export const arrayToMapByProp = (array, by) => {
  const data = {
    all: [],
  };
  data.map = array.reduce((obj, item) => {
    data.all.push(item[by]);
    obj[item[by]] = cloneDeep(item);
    return obj;
  }, {});

  return data;
};
